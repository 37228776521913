import React, {useState} from 'react';
import './ScrollableModalBody.css';
import Modal from "react-bootstrap/Modal";
import ScrollIndicator from "./ScrollIndicator";

export function scrollModalBody() {
    const element = document.querySelector('.modal-body');
    const topBar = document.querySelector('.scrollable-modal-body__top');
    const bottomBar = document.querySelector('.scrollable-modal-body__bottom');

    if (topBar)
        topBar.classList.remove('scrollable-modal-body_scroll-more-above');
    if (bottomBar)
        bottomBar.classList.remove('scrollable-modal-body_scroll-more-below');

    if (element.scrollHeight > element.clientHeight) {
        const showtop = (element.scrollTop > 0);
        const showbottom = (element.scrollTop + element.clientHeight < element.scrollHeight);

        if (showtop && topBar)
            topBar.classList.add('scrollable-modal-body_scroll-more-above');
        if (showbottom && bottomBar)
            bottomBar.classList.add('scrollable-modal-body_scroll-more-below');
    }
}

function ScrollableModalBody({children}) {
    const [scroll, setScroll] = useState(0);
    const element = '.modal-body';

    return (<>
        <ScrollIndicator scroll={scroll} element={element}/>
        {/*<div className={'scrollable-modal-body__top'}/>*/}
        <Modal.Body onScroll={() => {
            const e = document.querySelector(element);
            if (e)
                setScroll(e.scrollTop);
            scrollModalBody();
        }}>
            {children}
        </Modal.Body>
        <div className={'scrollable-modal-body__bottom'}/>
    </>);
}

export default ScrollableModalBody;