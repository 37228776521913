import {createSlice} from '@reduxjs/toolkit';

import {validateModificador} from '../helper/modificador';

const INITIAL_STATE = {
    selected: {},
    modificadores: [],
    editindex: -1,
};

export const articuloSlice = createSlice({
    name: 'articulo',
    initialState: INITIAL_STATE,
    reducers: {
        setArticulo: (state, {payload}) => {
            state.selected = JSON.parse(JSON.stringify(payload));
            state.selected.cantidad = 1;
            state.selected.comentarios = '';
            state.selected.precioIntegrado = payload.precio;
            state.editindex = -1;
        },
        setItem: (state, {payload}) => {
            state.selected = JSON.parse(JSON.stringify(payload.articulo));
            state.modificadores = JSON.parse(JSON.stringify(payload.articulomodificadores));
            state.editindex = payload.index;
        },
        resetArticulo: state => {
            Object.keys(INITIAL_STATE).forEach(key => state[key] = INITIAL_STATE[key])
        },
        setCantidad: (state, {payload}) => {
            state.selected.cantidad = payload;
        },
        setComentarios: (state, {payload}) => {
            state.selected.comentarios = payload;
        },
        initModificadores: (state, {payload}) => {
            const {articulo, modificadores} = payload;

            state.modificadores = articulo.modificador.map(modificador => {
                const newModificador = JSON.parse(JSON.stringify(modificadores[modificador]));
                newModificador.item = newModificador.item.map(item => {
                    return {...item, cantidad: 0}
                });

                newModificador.type = newModificador.multiple ? 'edit' :
                    (newModificador.minimo === 1 && newModificador.maximo === 1) ? 'radio' :
                        'checkbox';

                newModificador.valido = validateModificador(newModificador);

                return newModificador;
            });
        },
        setModificadorCantidad: (state, {payload}) => {
            const {modificador, item, cantidad} = payload;
            const current = state.modificadores[modificador];
            if (current.type === 'radio')
                current.item.forEach(item => item.cantidad = 0);
            current.item[item].cantidad = cantidad;
            current.valido = validateModificador(current);

            state.selected.precioIntegrado = state.modificadores.reduce(
                (prev, modificador) => prev + modificador.item.reduce(
                    (prev, item) => prev + item.cantidad * parseFloat(item.precio),
                    0
                ),
                parseFloat(state.selected.precio)
            );
        },
    },
});

export const {
    setArticulo,
    setItem,
    resetArticulo,
    setCantidad,
    setComentarios,
    initModificadores,
    setModificadorCantidad
} = articuloSlice.actions;
export const selectArticulo = state => state.articulo.selected;
export const selectArticuloActivo = state => Object.keys(state.articulo.selected).length !== 0;
export const selectCantidad = state => state.articulo.selected.cantidad;
export const selectComentarios = state => state.articulo.selected.comentarios;
export const selectPrecio = state => state.articulo.selected.precioIntegrado;
export const selectArticuloModificadores = state => state.articulo.modificadores;
export const selectEditIndex = state => state.articulo.editindex;

export default articuloSlice.reducer;
