import React from "react";
import {useDispatch, useSelector} from 'react-redux';
import Col from "react-bootstrap/Col";
import Form from "react-bootstrap/Form";

import {selectArticuloModificadores, setModificadorCantidad} from "../redux/articuloSlice";
import {getModificadorHelp} from "../helper/modificador";
import './Modificador.css';

function Modificador({modificadorindex, desactivado}) {
    const dispatch = useDispatch();
    const modificadores = useSelector(selectArticuloModificadores);
    const modificador = modificadores[modificadorindex];

    const label = modificador.nombre;
    const escojastr = getModificadorHelp(modificador);
    const valido = modificador.valido;
    const labelclasses = ['articulo-modal__modificador__title'];
    if (!valido && !desactivado)
        labelclasses.push('articulo-modal__modificador__title_invalid');

    if (!desactivado) {
        return (
            <Form.Group className={'articulo-modal__modificador'}>
                <Form.Label className={labelclasses.join(' ')}>{label} {escojastr}</Form.Label>
                {modificador.item.map((item, index) => {
                    const preciostr = item.precio > 0 ? ` (+${item.moneda}${item.precio})` : '';
                    const disponiblestr = item.activo ? '' : ' (no disponible)';

                    if (modificador.multiple)
                        return (
                            <Form.Row key={modificadorindex + '-' + index}>
                                <Col xs="auto">
                                    <input className={'articulo-modal__modificador-cantidad'}
                                           type="text"
                                           maxLength="2"
                                           size="2"
                                           value={item.cantidad}
                                           disabled={!item.activo}
                                           onChange={e => dispatch(setModificadorCantidad({
                                               modificador: modificadorindex,
                                               item: index,
                                               cantidad: e.target.value
                                           }))}
                                    />
                                </Col>
                                <Col>{item.nombre + preciostr + disponiblestr}</Col>
                            </Form.Row>
                        );
                    else
                        return (
                            <Form.Check key={index} className={'articulo-modal__modificador__' + modificador.type}>
                                <Form.Check.Label style={{width: '100%'}}>
                                    <Form.Check.Input type={modificador.type}
                                                      name={'modificador' + modificadorindex}
                                                      checked={item.cantidad !== 0}
                                                      disabled={!item.activo}
                                                      onChange={e => {
                                                          return dispatch(setModificadorCantidad({
                                                              modificador: modificadorindex,
                                                              item: index,
                                                              cantidad: e.target.checked ? 1 : 0
                                                          }));
                                                      }}
                                    /> {item.nombre + preciostr + disponiblestr}
                                </Form.Check.Label>
                            </Form.Check>
                        );
                })}
            </Form.Group>
        );
    } else {
        return (
            <div className={'articulo-modal__modificador-desactivado'}>
                <p className={labelclasses.join(' ')}>{label} {escojastr}</p>
                <ul>
                    {modificador.item.map((item, index) => {
                        const preciostr = item.precio > 0 ? ` (+${item.precio})` : '';
                        return <li key={modificadorindex + '-' + index}>{item.nombre + preciostr}</li>;
                    })}
                </ul>
            </div>
        );
    }
}

export default Modificador;
