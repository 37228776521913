import axios from "axios";

import config from "../config";


function pidePedidoStatus(pedido) {
    return axios({
        method: 'POST',
        url: config.url,
        headers: {
            'Content-Type': 'text/xml; charset=utf-8'
        },
        data: `<?xml version="1.0" encoding="UTF-8"?>
                <request command="pidePedidoStatus">
                <auth url="${config.qrCode}" />
                <pidePedidoStatus><pedido>${pedido}</pedido></pidePedidoStatus>
                </request>
        `
    });
}

export default pidePedidoStatus;