import React, {useEffect} from "react";
import {useDispatch, useSelector} from 'react-redux';
import Modal from "react-bootstrap/Modal";
import Table from "react-bootstrap/Table";
import Button from "react-bootstrap/Button";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import {AiOutlineDelete} from 'react-icons/ai';
import {FaMinusCircle, FaPlusCircle} from "react-icons/fa";
import {BsPencil} from 'react-icons/bs';

import {
    decrementCarritoItem,
    deleteCarritoItem,
    incrementCarritoItem,
    resetActivo,
    selectCarrito,
    selectTotal,
    setEnviando,
    setError,
    setGrabaResult
} from '../redux/carritoSlice';

import "./CarritoModal.css";
import grabaPedidoLocal from "../ws/grabaPedidoLocal";
import {setItem} from "../redux/articuloSlice";
import ScrollableModalBody, {scrollModalBody} from "./ScrollableModalBody";

function CarritoModal() {
    const dispatch = useDispatch();
    const carrito = useSelector(selectCarrito);
    const total = useSelector(selectTotal);

    useEffect(() => {
        scrollModalBody()
    }, []);

    const editCarritoItem = index => {
        dispatch(resetActivo());
        const item = carrito[index];
        dispatch(setItem({...item, index}));
    };

    return (
        <Modal show={true} animation={false} scrollable={true} onHide={() => dispatch(resetActivo())}>
            <Modal.Header closeButton>
                <Modal.Title>Su orden</Modal.Title>
            </Modal.Header>

            <ScrollableModalBody>
                <Table striped bordered responsive className='carrito-table'>
                    <tbody>
                    {!carrito.length &&
                    <tr>
                        <td>El carrito está vacío.</td>
                    </tr>
                    }
                    {carrito.map((item, index) => {
                        const {cantidad, nombre, precioIntegrado, comentarios, moneda} = item.articulo;
                            const precioUnitario = parseFloat(precioIntegrado);
                            const precioTotal = precioUnitario * cantidad;

                            return (
                                <tr key={index} className='carrito-item'>
                                    <td className='carrito-item__cantidad-col'>
                                        <FaMinusCircle className='carrito-item__cantidad-boton'
                                                       onClick={e => dispatch(decrementCarritoItem(index))}/>
                                        <span className='carrito-item__cantidad'>{cantidad}</span>
                                        <FaPlusCircle className='carrito-item__cantidad-boton'
                                                      onClick={e => dispatch(incrementCarritoItem(index))}/>
                                    </td>
                                    <td className='carrito-item__descripcion-col'>
                                        <div className='carrito-item__descripcion'>{nombre}</div>
                                        {
                                            item.articulomodificadores.map((modificador, modificadorindex) =>
                                                modificador.item.filter(linea => linea.cantidad > 0).map((linea, lineaindex) =>
                                                    <div className='carrito-item__modificador'
                                                         key={`${modificadorindex}-${lineaindex}`}>
                                                        {linea.cantidad > 1 ? `${linea.cantidad} ` : ''}
                                                        {linea.nombre}
                                                    </div>
                                                )
                                            )
                                        }
                                        {comentarios &&
                                        <div className='carrito-item__comentarios'>Comentarios: {comentarios}</div>
                                        }
                                    </td>
                                    <td className='carrito-item__precio-col'>
                                        <div className='carrito-item__precio'>
                                            {moneda}
                                            {precioTotal.toLocaleString('en', {minimumFractionDigits: 2})}
                                            <span className='carrito-item__botones'>
                                            <BsPencil className='carrito-item__editar-boton'
                                                      onClick={e => editCarritoItem(index)}/>
                                            <AiOutlineDelete className='carrito-item__borrar-boton'
                                                             onClick={e => dispatch(deleteCarritoItem(index))}/>
                                        </span>
                                        </div>
                                        {cantidad > 1 && <div
                                            className="carrito-item__precio-unitario">
                                            {precioUnitario.toLocaleString('en', {minimumFractionDigits: 2})}
                                            &nbsp;c/u
                                        </div>}
                                    </td>
                                </tr>
                            );
                        }
                    )}
                    </tbody>
                </Table>
            </ScrollableModalBody>

            <Modal.Footer>
                {carrito.length > 0 &&
                <Container>
                    <Row className='carrito__footer'>
                        <Col xs='6' className='carrito__footer-left'>
                            <div className='carrito__total'>
                                Total:{' '}
                                {carrito.length ? carrito[0].articulo.moneda : ''}
                                {total.toLocaleString('en', {minimumFractionDigits: 2})}
                            </div>
                        </Col>
                        <Col xs='6' className='carrito__footer-right'>
                            <Button variant="primary" size="sm" className='carrito-modal__submit'
                                    onClick={e => {
                                        dispatch(setEnviando());
                                        grabaPedidoLocal(carrito).then(
                                            (response) => {
                                                // console.log(response);
                                                dispatch(setGrabaResult(response.data));
                                            },
                                            (error) => {
                                                console.error(error);
                                                dispatch(setError(error));
                                            }
                                        );
                                        return null;
                                    }}>
                                Enviar orden
                            </Button>
                        </Col>
                    </Row>
                </Container>
                }
            </Modal.Footer>
        </Modal>
    );
}

export default CarritoModal;