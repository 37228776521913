import React from 'react';
import './ScrollIndicator.css';

function ScrollIndicator({scroll, element}) {
    const e = document.querySelector(element);
    const height = e ? e.scrollHeight - e.clientHeight : 1;
    const scrolled = (scroll / height) * 100;

    return <div className='scroll__indicator'>
        <div className={'scroll__progressbar'} style={{width: `${scrolled}%`}}/>
    </div>
}

export default ScrollIndicator;
