import React, {useEffect, useState} from 'react';
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Spinner from "react-bootstrap/Spinner";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import {useDispatch, useSelector} from "react-redux";

import {
    resetEnviando,
    selectError,
    selectErrorText,
    selectGrabaResult,
    selectSuccess,
    setError,
    setErrorText,
    setSuccess
} from "../redux/carritoSlice";
import pidePedidoStatus from '../ws/pidePedidoStatus';
import './Enviando.css';
import LogRocket from "logrocket";

function Enviando() {
    const [startTimestamp] = useState(Date.now());
    const dispatch = useDispatch();
    const error = useSelector(selectError);
    const errortext = useSelector(selectErrorText);
    const grabaResult = useSelector(selectGrabaResult);
    const success = useSelector(selectSuccess);

    useEffect(() => {
        if (!error && !errortext && !success)
            console.log('Enviando pedido', Date())
    }, [error, errortext, success]);

    useEffect(() => {
        if (grabaResult && !error && !errortext && !success) {
            switch (grabaResult.status) {
                case 200:
                    const id = grabaResult.pedido.id;
                    const interval = setInterval(() => {
                        pidePedidoStatus(id).then(
                            (response) => {
                                //console.log(response);
                                const pedidoStatus = response.data.pedidoStatus;
                                const {status, descripcion, pedido} = pedidoStatus;

                                if (status === 2) {
                                    dispatch(setSuccess(true));
                                    console.log('OK Enviando pedido', Date(), pedido);
                                    LogRocket.track('Aceptado');
                                }
                                if (status === 3) {
                                    dispatch(setError(descripcion));
                                    console.log('ERR Enviando pedido', Date(), pedido);
                                    LogRocket.track('Rechazado');
                                }

                                if (status >= 2) {
                                    console.log('TIME Enviando pedido', (Date.now() - startTimestamp) / 1000, 'segundos');
                                    clearInterval(interval);
                                }
                            },
                            (error) => {
                                console.error(error);
                                dispatch(setError(error));
                                clearInterval(interval);
                            }
                        );
                    }, 15000);
                    break;
                case 510:
                    dispatch(setErrorText('El horario de operación del restaurante concluyó.'));
                    break;
                case 531:
                    dispatch(setErrorText('Su código está desactivado o es inválido.'));
                    break;
                default:
                    dispatch(setError(`${grabaResult.status} ${grabaResult.message}`));
                    break;
            }
        }
    }, [grabaResult, dispatch, error, errortext, success, startTimestamp]);

    if (success) {
        return (
            <Modal.Dialog className='enviando__success__dialog'>
                <Modal.Header className='enviando__success__header'>Orden recibida</Modal.Header>
                <Modal.Body className='enviando__success__body'>
                    <p className='enviando__success__text'>Su orden se recibió con éxito.</p>
                    <p className='enviando__success__text'>Si tiene alguna duda consulte a su mesero.</p>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="primary" size="sm" onClick={e => dispatch(resetEnviando())}>
                        Regresar
                    </Button>
                </Modal.Footer>
            </Modal.Dialog>
        );
    } else if (!error && !errortext) {
        return (
            <Modal.Dialog className='enviando__dialog'>
                <Modal.Header className='enviando__header'>Estamos enviando su orden</Modal.Header>
                <Modal.Body className='enviando__body'>
                    <p className='enviando__text'>Por favor espere en esta pantalla hasta recibir confirmación.</p>
                    <p className='enviando__text'>Si tiene alguna duda consulte a su mesero.</p>
                    <Container>
                        <Row><Col className='d-flex justify-content-center'>
                            <Spinner className='enviando-spinner' animation='border'/>
                        </Col></Row>
                    </Container>
                </Modal.Body>
            </Modal.Dialog>
        );
    } else {
        console.error(error, errortext);
        return (
            <Modal.Dialog className='enviando__error__dialog'>
                <Modal.Header className='enviando__error__header'>Hubo un problema con su orden</Modal.Header>
                <Modal.Body className='enviando__error__body'>
                    {errortext && <p className='enviando__error__text'>{errortext}</p>}
                    <p className='enviando__error__text'>Por favor consulte a su mesero.</p>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="primary" size="sm" onClick={e => dispatch(resetEnviando())}>
                        Regresar
                    </Button>
                </Modal.Footer>
            </Modal.Dialog>
        );
    }
}

export default Enviando;