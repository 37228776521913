import React from 'react';
import Catalogo from './components/Catalogo';
import config, {initialize} from "./config";
import './App.css';

const DEFAULTREDIRECT = 'https://elcheff.com.mx';

/**
 * @return {null}
 */
function App() {
    if (initialize()) {
        const link = document.createElement('link');
        link.rel = 'stylesheet';
        link.type = 'text/css';
        link.href = `${config.imageserver}/${config.domain}/${config.domain}-p.css`;
        document.getElementsByTagName('head')[0].appendChild(link);

        return (
            <Catalogo/>
        );
    } else {
        window.location.href = DEFAULTREDIRECT;
        return null;
    }
}

export default App;
