import {configureStore, getDefaultMiddleware} from '@reduxjs/toolkit';
import articuloReducer from './articuloSlice';
import catalogoReducer from './catalogoSlice';
import carritoReducer, {CARRITOKEY, persistCarrito} from './carritoSlice';
import sucursalReducer from './sucursalSlice';
import LogRocket from 'logrocket';

const middleware = getDefaultMiddleware();
if (window.cheffdomlog)
    middleware.push(LogRocket.reduxMiddleware());
middleware.push(persistCarrito);

const store = configureStore({
    reducer: {
        articulo: articuloReducer,
        catalogo: catalogoReducer,
        carrito: carritoReducer,
        sucursal: sucursalReducer
    },
    middleware
});

window.addEventListener('storage', (event) => {
    if ((event.key === CARRITOKEY) && !document.hasFocus() && (event.newValue !== JSON.stringify(store.getState().carrito))) {
        const payload = JSON.parse(event.newValue);
        store.dispatch({'type': 'carrito/overwriteCarrito', 'payload': payload});
    }
});


export default store;
