import {createSlice} from '@reduxjs/toolkit';

export const CARRITOKEY = `cheffdomCarritoState${window.location.pathname}`;

const INITIALSTATE = {
    carrito: [],
    activo: false,
    enviando: false,
    error: null,
    errortext: "",
    result: null,
    success: false
};

const persistedState = localStorage.getItem(CARRITOKEY)
    ? {...INITIALSTATE, ...JSON.parse(localStorage.getItem(CARRITOKEY))}
    : INITIALSTATE;

export const persistCarrito = store => next => action => {
    let result = next(action);
    if (action.type.startsWith('carrito'))
        localStorage.setItem(CARRITOKEY, JSON.stringify(store.getState().carrito));
    return result;
};

export const carritoSlice = createSlice({
    name: 'carrito',
    initialState: persistedState,
    reducers: {
        addCarrito: (state, {payload}) => {
            const editindex = payload.editindex;
            delete payload.editindex;

            if (editindex === -1)
                state.carrito.push(payload);
            else
                state.carrito[editindex] = payload;
        },
        deleteCarritoItem: (state, {payload}) => {
            state.carrito.splice(payload, 1);
        },
        incrementCarritoItem: (state, {payload}) => {
            if (state.carrito[payload].articulo.cantidad < 99)
                state.carrito[payload].articulo.cantidad += 1;
        },
        decrementCarritoItem: (state, {payload}) => {
            if (state.carrito[payload].articulo.cantidad > 1)
                state.carrito[payload].articulo.cantidad -= 1;
            else
                state.carrito.splice(payload, 1);
        },
        setActivo: (state) => {
            state.activo = true;
        },
        resetActivo: (state) => {
            state.activo = false;
        },
        setEnviando: (state) => {
            state.enviando = true;
            state.error = null;
            state.result = null;
        },
        resetEnviando: (state) => {
            Object.keys(INITIALSTATE).forEach(key => state[key] = INITIALSTATE[key])
        },
        setError: (state, {payload}) => {
            state.error = payload;
        },
        setErrorText: (state, {payload}) => {
            state.errortext = payload;
        },
        setGrabaResult: (state, {payload}) => {
            state.result = payload;
        },
        setSuccess: (state, {payload}) => {
            if (state.enviando)
                state.success = payload;
        },
        overwriteCarrito: (state, {payload}) => {
            Object.keys(payload).forEach(key => state[key] = payload[key])
        }
    }
});


export const {
    addCarrito,
    setActivo,
    resetActivo,
    deleteCarritoItem,
    incrementCarritoItem,
    decrementCarritoItem,
    setError,
    setErrorText,
    setGrabaResult,
    setQueryResult,
    setEnviando,
    resetEnviando,
    setSuccess,
    overwriteCarrito
} = carritoSlice.actions;

export const selectCantidad = state => state.carrito.carrito.reduce((prev, item) => prev + item.articulo.cantidad, 0);
export const selectTotal = state => state.carrito.carrito.reduce(
    (prev, item) =>
        prev + item.articulo.precioIntegrado * item.articulo.cantidad,
    0
);
export const selectCarrito = state => state.carrito.carrito;
export const selectCarritoActivo = state => state.carrito.activo;
export const selectCarritoEnviando = state => state.carrito.enviando;
export const selectError = state => state.carrito.error;
export const selectErrorText = state => state.carrito.errortext;
export const selectGrabaResult = state => state.carrito.result;
export const selectSuccess = state => state.carrito.success;

export default carritoSlice.reducer;