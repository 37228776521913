import React from 'react';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import {BsArrowsCollapse, BsArrowsExpand} from 'react-icons/bs';
import {useDispatch, useSelector} from "react-redux";

import Articulos from './Articulos';
import imageurl from '../helper/imageurl';
import {selectCollapse, selectDivision, setCollapse} from "../redux/catalogoSlice";

import './Divisiones.css';

function Divisiones({menu}) {
    var divisiones = menu.division.map(division => <Division key={division.divisionId} division={division}/>);
    return <div className='divisiones'>{divisiones}</div>;
}

function Division({division}) {
    const collapse = useSelector(selectCollapse);
    const activa = useSelector(selectDivision);
    const dispatch = useDispatch();

    const divisionClassId = `division__${division.divisionId}`;
    const divisionClasses = ['division'];
    if (collapse)
        divisionClasses.push('division_collapse');

    const headerClasses = ['division-header'];
    if (divisionClassId === activa)
        headerClasses.push(collapse ? 'division__header_activa-collapsed' : 'division__header_activa');

    return (
        <div className={divisionClasses.join(' ')}
             id={divisionClassId}
             onClick={() => {
                 if (collapse)
                     dispatch(setCollapse({collapse: !collapse, division: divisionClassId}));
             }}
        >
            <Row className={headerClasses.join(' ')}
                 onClick={() => dispatch(setCollapse({collapse: !collapse, division: divisionClassId}))}
            >
                <Col xs={10} className='division__nombre'>{division.nombre}</Col>
                <Col xs={2}
                     className='align-self-end'
                     style={{textAlign: 'right'}}
                >
                    {collapse ? <BsArrowsExpand/> : <BsArrowsCollapse/>}&nbsp;
                </Col>
            </Row>
            <Row>
                <Col xs={12} className='division__descripcion'>{division.descripcion}</Col>
            </Row>
            <Row className='articulos'>
                {division.imagen !== '' &&
                <Col className='division__imagen-col' xs={12} lg={6} xl={4}>
                    <div
                        className='division__imagen'
                        style={{backgroundImage: `url(${imageurl(division.imagen, division.imagenTipo)})`}}
                    />
                </Col>
                }
                {!collapse && <Articulos division={division}/>}
            </Row>
        </div>
    );
}

export default Divisiones;
