import React from 'react';
import Navbar from 'react-bootstrap/Navbar'
import NombreLugar from './NombreLugar'
import CarritoBoton from './CarritoBoton'
import ScrollIndicator from "./ScrollIndicator";
import './Header.css';

function Header({scroll}) {
    const showLogo = (scroll <= 0);

    const classes = ['header'];
    classes.push(showLogo ? 'header_long' : 'header_short');

    return (<>
        <Navbar className={classes.join(' ')} bg={'light'} fixed={'top'}>
            <NombreLugar scroll={scroll}/>
            <CarritoBoton scroll={scroll}/>
        </Navbar>
        {
            scroll > 0 &&
            <div className='header__scroll'>
                <ScrollIndicator scroll={scroll} element='#root'/>
            </div>
        }
    </>);
}

export default Header;