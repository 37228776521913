import React from 'react';
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import './Footer.css';

function Footer() {
    return (
        <Row>
            <Col className={'footer'}>
                <div className={'footer__desarrollado'}>Desarrollado por <a href='https://elcheff.com.mx'>El Cheff</a>
                </div>
            </Col>
        </Row>
    );
}

export default Footer;