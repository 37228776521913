const config = {};

export function initialize() {
    const {pathname, hostname, href} = window.location;

    const server = hostname.replace('p.', '');
    config.qrCode = href;
    config.domain = pathname.split('/')[1];
    config.url = `//${server}/dom/a/ws.html`;

    // globals.js
    config.imageserver = window.cheffdomimageserver;

    return pathname !== '/';
}

export default config;
