import {createSlice} from '@reduxjs/toolkit';

export const sucursalSlice = createSlice({
    name: 'sucursal',
    initialState: {
        loaded: null,
        sucursal: null,
        activa: null,
        menu: null,
        dominio: null,
        nombre: null,
        mesa: null
    },
    reducers: {
        setSucursal: (state, {payload}) => {
            state.loaded = true;
            if (payload) {
                state.sucursal = payload.sucursal;
                state.activa = payload.activa;
                state.menu = payload.menu;
                state.dominio = payload.nombre_dominio;
                state.nombre = payload.nombre_sucursal;
                state.mesa = payload.mesa;
            }
        }
    }
});


export const {setSucursal} = sucursalSlice.actions;
export const selectSucursalActiva = state => !state.sucursal.loaded || (state.sucursal.activa && state.sucursal.menu);
export const selectSucursalDominio = state => state.sucursal.dominio;
export const selectSucursalNombre = state => state.sucursal.nombre;
export const selectSucursalMesa = state => state.sucursal.mesa;

export default sucursalSlice.reducer;