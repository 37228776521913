import React from 'react';
import Navbar from 'react-bootstrap/Navbar';
import {useSelector} from 'react-redux';

import config from '../config';
import './NombreLugar.css';
import {selectSucursalDominio, selectSucursalNombre} from "../redux/sucursalSlice";

function NombreLugar({scroll}) {
    const dominio = useSelector(selectSucursalDominio);
    const nombre = useSelector(selectSucursalNombre);

    const showLogo = (scroll <= 0);
    const logo = `${config.imageserver}/${config.domain}/logo.png`;

    const imageclasses = ['nombre-lugar__imagen'];
    if (!showLogo)
        imageclasses.push('nombre-lugar__imagen_hide');

    return (
        <>
            <div className={imageclasses.join(' ')} style={{backgroundImage: `url(${logo})`}}/>
            {
                !showLogo &&
                <Navbar.Text className={'nombre-lugar__text'}>
                    <span className='nombre-lugar__text__dominio'>{dominio}</span>
                    <span className='nombre-lugar__text__join'> - </span>
                    <span className='nombre-lugar__text__sucursal'>{nombre}</span>
                </Navbar.Text>
            }
        </>
    );
}

export default NombreLugar;