import React from 'react';
import {useDispatch, useSelector} from 'react-redux';
import Navbar from 'react-bootstrap/Navbar'
import {AiOutlineShoppingCart} from "react-icons/ai";

import {selectCantidad, selectCarritoActivo, setActivo} from "../redux/carritoSlice";
import {selectArticuloActivo} from "../redux/articuloSlice";
import {selectSucursalMesa} from "../redux/sucursalSlice";
import './CarritoBoton.css';

function CarritoBoton({scroll}) {
    const dispatch = useDispatch();
    const carritoactivo = useSelector(selectCarritoActivo);
    const articuloactivo = useSelector(selectArticuloActivo);
    const cantidad = useSelector(selectCantidad);
    const mesa = useSelector(selectSucursalMesa);
    const showMesa = (scroll <= 0);

    const cantidadClasses = ['carrito-boton__cantidad'];
    if (carritoactivo || articuloactivo || cantidad === 0)
        cantidadClasses.push('carrito-boton__cantidad_hide');

    return (
        <Navbar.Collapse className='justify-content-end'>
            {mesa &&
            <Navbar.Text className='carrito-hover' onClick={e => dispatch(setActivo())}>
                <div className='carrito-boton'>
                    <AiOutlineShoppingCart className='carrito-boton__icono'/>
                    <div className={cantidadClasses.join(' ')}>{cantidad}</div>
                </div>
                {showMesa &&
                <div className='carrito-mesa'>Mesa {mesa}</div>
                }
            </Navbar.Text>
            }
        </Navbar.Collapse>
    );
}

export default CarritoBoton;