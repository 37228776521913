import {createSlice} from '@reduxjs/toolkit';
import {calculaRangoPrecios} from "../helper/articulo";

export const catalogoSlice = createSlice({
    name: 'catalogo',
    initialState: {
        catalogo: {},
        modificadores: [],
        collapse: false,
        division: null
    },
    reducers: {
        setCatalogo: (state, {payload}) => {
            state.catalogo = payload;
            if (payload.status === 200) {
                if (payload.modificador) {
                    state.modificadores = payload.modificador.reduce(function (accum, currentVal) {
                        currentVal.item && currentVal.item.forEach(item => {
                            item.moneda = payload.moneda;
                        });
                        accum[currentVal.modificadorId] = currentVal;
                        return accum;
                    }, {});
                }

                if (payload.menu) {
                    payload.menu[0].division.forEach(division => {
                        division.articulo && division.articulo.forEach(articulo => {
                            calculaRangoPrecios(articulo, state.modificadores);
                            articulo.moneda = payload.moneda;
                        })
                    });
                }
            }
        },
        setCollapse: (state, {payload}) => {
            const {collapse, division} = payload;
            state.collapse = collapse;
            state.division = division;
        },
        setDivision: (state, {payload}) => {
            state.division = payload;
        },
    }
});


export const {setCatalogo, setCollapse, setDivision} = catalogoSlice.actions;
export const selectCatalogo = state => state.catalogo.catalogo;
export const selectModificadores = state => state.catalogo.modificadores;
export const selectCollapse = state => state.catalogo.collapse;
export const selectDivision = state => state.catalogo.division;

export default catalogoSlice.reducer;