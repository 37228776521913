import axios from "axios";
import X2JS from 'node-x2js';

import config from "../config";


export function xmlPedidoLocal(carrito) {
    const request = {
        url: config.qrCode,
        sucursal: config.sucursal,
        pedidoLinea: carrito.map((item, index) => {
            const {articulo, articulomodificadores} = item;
            const linea = {
                articulo: articulo.articuloId,
                cantidad: articulo.cantidad,
                precio: articulo.precioIntegrado,
            };

            if (articulo.comentarios)
                linea.comentarios = articulo.comentarios;

            const modificador = articulomodificadores.reduce(
                (prev, modificador) => {
                    modificador.item.filter(cur => cur.cantidad > 0).reduce(
                        (prev, cur) => {
                            for (let i = 0; i < cur.cantidad; ++i)
                                prev.push(cur.itemId);
                            return prev;
                        }, prev
                    );
                    return prev;
                }, []
            );

            if (modificador.length > 0)
                linea.modificador = modificador;

            return linea;
        })
    };

    return new X2JS().json2xml_str(request);
}


function grabaPedidoLocal(carrito) {
    const request = `<?xml version="1.0" encoding="UTF-8"?>
            <request command="grabaPedidoLocal">
            <auth url="${config.qrCode}" />
            <grabaPedidoLocal>${xmlPedidoLocal(carrito)}</grabaPedidoLocal>
            </request>
    `;

    return axios({
        method: 'POST',
        url: config.url,
        headers: {
            'Content-Type': 'text/xml; charset=utf-8'
        },
        data: request
    });
}

export default grabaPedidoLocal;