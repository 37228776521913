export function getModificadorHelp(modificador) {
    const numitems = Object.keys(modificador.item).length;

    return (
        (modificador.minimo === 1 && modificador.maximo === 1) ? "" :
            (modificador.minimo > 0 && modificador.minimo === modificador.maximo) ? `(escoja ${modificador.maximo})` :
                (modificador.minimo > 0 &&
                    modificador.maximo > 0 &&
                    (modificador.maximo < numitems || modificador.multiple)
                ) ? `(escoja ${modificador.minimo} a ${modificador.maximo})` :
                    (modificador.minimo > 0) ? `(escoja mínimo ${modificador.minimo})` :
                        (modificador.maximo > 0) ? `(escoja máximo ${modificador.maximo})` :
                            ""
    )
}

export function validateModificador(modificador) {
    const cantidad = modificador.item.reduce((prev, item) => prev + (item.cantidad ? parseInt(item.cantidad) : 0), 0);
    // !modificador.maximo => no hay limite
    return !modificador.maximo || (cantidad >= modificador.minimo && cantidad <= modificador.maximo);
}