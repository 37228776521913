import React from 'react';
import Image from 'react-bootstrap/Image';
import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';
import {useDispatch, useSelector} from 'react-redux';

import imageurl from '../helper/imageurl';
import {initModificadores, setArticulo} from '../redux/articuloSlice';
import {selectModificadores} from "../redux/catalogoSlice";
import './Articulos.css';

function Articulos({division}) {
    var articulos = [];

    if (division.articulo)
        articulos = division.articulo
            .filter(articulo => articulo.precio >= 0)
            .map(articulo => <Articulo key={articulo.articuloId} articulo={articulo}/>);

    return <>{articulos}</>;
}

function Articulo({articulo}) {
    const dispatch = useDispatch();
    const modificadores = useSelector(selectModificadores);

    const updateArticulo = articulo => {
        dispatch(setArticulo(articulo));
        dispatch(initModificadores({articulo: articulo, modificadores: modificadores}));
    };

    const articuloId = `articulo__${articulo.articuloId}`;

    const articuloImagenColClasses = ['articulo__imagen-col'];
    if (articulo.imagen === '')
        articuloImagenColClasses.push('articulo__imagen-col_empty');

    return (
        <Col xs={12} lg={6} xl={4} className='articulo'
             id={articuloId}
             onClick={() => updateArticulo(articulo)}>
            <Row className={'articulo__row'}>
                <Col className={articuloImagenColClasses.join(' ')}>
                    {articulo.imagen !== '' &&
                    <Image className={'articulo__imagen'}
                           src={imageurl(articulo.imagen, articulo.imagenTipo)}
                           alt={articulo.nombre}
                    />
                    }
                </Col>
                <Col className={'articulo__nombre-col'}>
                    <div className={'articulo__nombre'}>{articulo.nombre}</div>
                    <div className={'articulo__descripcion'}>{articulo.descripcion}</div>
                </Col>
                <Col className={'articulo__precio-col'}>
                    {
                        !articulo.desactivado ?
                            <div className={'articulo__precio'}>
                                { articulo.moneda }
                                { articulo.preciominimo.toLocaleString('en', {minimumFractionDigits: 2})}
                            </div> :
                            <div className={'articulo__precio-desactivado'}>No disponible</div>
                    }
                </Col>
            </Row>
        </Col>
    );
}


export default Articulos;
