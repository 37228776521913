export function calculaRangoPrecios(articulo, modificadores) {
    let preciominimo = parseFloat(articulo.precio);

    articulo.modificador.forEach(modificadorIndex => {
        const modificador = modificadores[modificadorIndex];
        if (modificador.minimo > 0) {
            preciominimo = modificador.item
                .sort((a, b) => parseFloat(a.precio) - parseFloat(b.precio))
                .slice(0, modificador.minimo)
                .reduce((prev, curr) => prev += parseFloat(curr.precio), preciominimo)
            ;
        }
    });

    articulo.preciominimo = preciominimo;
}
