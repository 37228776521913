import React, {useEffect, useState} from 'react';
import Container from "react-bootstrap/Container";
import Col from "react-bootstrap/Col";
import Row from "react-bootstrap/Row";
import Spinner from "react-bootstrap/Spinner";
import Modal from "react-bootstrap/Modal";
import LogRocket from 'logrocket';
import toPx from 'unit-to-px';

import Header from './Header'
import Divisiones from './Divisiones';
import Footer from './Footer'
import ArticuloModal from "./ArticuloModal";
import CarritoModal from "./CarritoModal";
import Enviando from './Enviando';
import {useDispatch, useSelector} from 'react-redux';
import {selectArticuloActivo} from '../redux/articuloSlice';
import {selectCatalogo, selectCollapse, selectDivision, setCatalogo} from '../redux/catalogoSlice';
import {selectCarritoActivo, selectCarritoEnviando} from '../redux/carritoSlice';
import pideCatalogo from "../ws/pideCatalogo";

import './Catalogo.css';
import pideSucursalStatus from "../ws/pideSucursalStatus";
import {selectSucursalActiva, setSucursal} from "../redux/sucursalSlice";
import config from "../config";


function Catalogo() {
    const [scroll, setScroll] = useState(0);
    const [error, setError] = useState('');
    const catalogo = useSelector(selectCatalogo);
    const showCarrito = useSelector(selectCarritoActivo);
    const showPopup = useSelector(selectArticuloActivo);
    const showEnviando = useSelector(selectCarritoEnviando);
    const sucursalActiva = useSelector(selectSucursalActiva);

    const dispatch = useDispatch();

    useEffect(() => {
        pideCatalogo()
            .then(
                (response) => {
                    if (!window.cheffdomrelease)
                        console.log('Catalogo', response.data);
                    dispatch(setCatalogo(response.data));
                },
                (error) => {
                    console.error(error);
                    setError(error);
                }
            );

        pideSucursalStatus()
            .then(
                (response) => {
                    const sucursalStatus = response.data.sucursalStatus;

                    if (!window.cheffdomrelease)
                        console.log('Sucursal', response.data);

                    if (sucursalStatus) {
                        console.log(`${sucursalStatus.nombre_dominio} - ${sucursalStatus.nombre_sucursal}`);
                        if (sucursalStatus.mesa)
                            console.log(`Mesa ${sucursalStatus.mesa}`);
                    }

                    if (sucursalStatus && config.qrCode.split('/')[4].search('-') !== -1)
                        sucursalStatus.activa = 1;

                    if (window.cheffdomlog) {
                        LogRocket.track(
                            (sucursalStatus && sucursalStatus.mesa) ?
                                `Mesa ${sucursalStatus.mesa}` :
                                'Sin mesa'
                        );

                        if (sucursalStatus) {
                            const name = sucursalStatus.mesa ? `mesa-${sucursalStatus.mesa}` : 'sin-mesa';
                            LogRocket.identify(
                                `${sucursalStatus.dominio}-${sucursalStatus.sucursal}-${sucursalStatus.menu}-${sucursalStatus.mesa}`,
                                {
                                    name: `${sucursalStatus.nombre_dominio} - ${sucursalStatus.nombre_sucursal}`,
                                    email: `${name}@${sucursalStatus.dominio}`
                                }
                            )
                        }
                    }

                    dispatch(setSucursal(response.data.sucursalStatus));
                },
                (error) => {
                    console.error(error);
                    setError(error);
                }
            );

        window.addEventListener('scroll', (event) => {
            const newScroll = window.scrollY;
            setScroll(newScroll);
        })
    }, [dispatch]);

    const divisionActiva = useSelector(selectDivision);
    const collapse = useSelector(selectCollapse);

    useEffect(() => {
        if (divisionActiva) {
            const division = document.querySelector('#' + divisionActiva);
            const catalogo_main = document.querySelector('.catalogo__main');
            const padding_main = toPx(window.getComputedStyle(catalogo_main)
                .getPropertyValue('padding-top'));
            const division_margin = toPx(window.getComputedStyle(division)
                .getPropertyValue('margin-top'));

            if (padding_main !== division.offsetTop - division_margin) {
                const header_short__height = document.querySelector('.header').clientHeight;
                window.scrollTo({top: division.offsetTop - header_short__height, behavior: "smooth"});
            }
        }
    }, [divisionActiva, collapse]);


    let errormessage = '';
    if (error)
        errormessage = 'Por el momento no podemos atender su solicitud.';
    else if (catalogo.message && catalogo.message !== 'OK')
        if (catalogo.message === 'codigo_desactivado')
            errormessage = 'El código que utilizó ya expiró.';
        else if (catalogo.message === 'codigo_noexiste')
            errormessage = 'El código que utilizó es inválido.';
        else
            errormessage = 'Hay un problema con el código que utilizó.';
    else if (sucursalActiva === 0)
        errormessage = 'Por el momento el restaurante no está recibiendo pedidos en linea.';

    if (errormessage)
        console.error(errormessage);

    if (showEnviando) {
        return <Enviando/>;
    } else if (errormessage) {
        return (
            <Modal.Dialog>
                <Modal.Body>
                    <p>{errormessage}</p>
                    <p>Por favor consulte a su mesero.</p>
                </Modal.Body>
            </Modal.Dialog>
        );
    } else if (!catalogo.message) {
        return (
            <Container className='h-100'>
                <Row className='h-100'><Col className='d-flex justify-content-center h-100-center'>
                    <Spinner className='catalogo-spinner' animation='border'/>
                </Col></Row>
            </Container>
        );
    } else {
        return (
            <div className='catalogo'>
                <Header scroll={scroll}/>
                <Container className='catalogo__main'>
                    {/*<DebugBootstrap/>*/}
                    <Divisiones menu={catalogo.menu[0]}/>
                    <Footer/>
                </Container>
                {showPopup && <ArticuloModal/>}
                {showCarrito && <CarritoModal/>}
            </div>
        );
    }
}

export default Catalogo;
