import React, {useEffect} from 'react';
import Modal from "react-bootstrap/Modal";
import Media from "react-bootstrap/Media";
import Image from "react-bootstrap/Image";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Button from "react-bootstrap/Button";
import Form from "react-bootstrap/Form";
import {FaMinusCircle, FaPlusCircle} from "react-icons/fa";
import {useDispatch, useSelector} from "react-redux";

import {
    resetArticulo,
    selectArticulo,
    selectArticuloModificadores,
    selectCantidad,
    selectComentarios,
    selectEditIndex,
    selectPrecio,
    setCantidad,
    setComentarios,
} from '../redux/articuloSlice';
import {addCarrito} from '../redux/carritoSlice';
import imageurl from "../helper/imageurl";
import Modificador from "./Modificador";
import './ArticuloModal.css';
import {selectSucursalMesa} from "../redux/sucursalSlice";
import ScrollableModalBody, {scrollModalBody} from "./ScrollableModalBody";


function ArticuloModal() {
    const articulo = useSelector(selectArticulo);
    const cantidad = useSelector(selectCantidad);
    const comentarios = useSelector(selectComentarios);
    const precio = useSelector(selectPrecio);
    const mesa = useSelector(selectSucursalMesa);
    const editindex = useSelector(selectEditIndex);
    const dispatch = useDispatch();

    const updateCantidad = (value, acceptEmpty) => {
        if (value === "" && acceptEmpty) {
            dispatch(setCantidad(''));
        } else {
            const val = parseInt(value);
            dispatch(setCantidad(
                isNaN(val) || val < 1 ? 1 :
                    val > 99 ? 99 :
                        val));
        }
    };

    useEffect(() => {
        scrollModalBody()
    }, []);

    const modificadores = articulo.modificador.map(
        (modificador, index) => <Modificador
            key={index}
            modificadorindex={index}
            desactivado={!mesa}
        />
    );

    const articulomodificadores = useSelector(selectArticuloModificadores);

    const canSubmit =
        articulomodificadores.reduce((prev, item) => prev && item.valido, true) &&
        (cantidad !== '') &&
        (cantidad > 0);

    const cantidadClasses = ['articulo-modal__cantidad'];
    if (cantidad === '' || cantidad < 1 || isNaN(cantidad))
        cantidadClasses.push('articulo-modal__cantidad_invalid');

    const menosClasses = ['articulo-modal__cantidad-button'];
    if (cantidad === '' || cantidad <= 1 || isNaN(cantidad))
        menosClasses.push('articulo-modal__cantidad-button_disabled');

    const masClasses = ['articulo-modal__cantidad-button'];
    if (cantidad >= 99)
        masClasses.push('articulo-modal__cantidad-button_disabled');

    return (
        <Modal show={true} animation={false} scrollable={true} onHide={() => dispatch(resetArticulo())}>
            <Modal.Header closeButton>
                <Modal.Title className='articulo-modal__nombre'>{articulo.nombre}</Modal.Title>
            </Modal.Header>

            <ScrollableModalBody>
                <Media>
                    {articulo.imagen !== '' &&
                    <Image className='articulo-modal__imagen'
                           src={imageurl(articulo.imagen, articulo.imagenTipo)}
                           alt={articulo.nombre}
                    />
                    }
                    <Media.Body className='d-flex align-self-center articulo-modal__descripcion'>
                        {articulo.descripcion}
                    </Media.Body>
                </Media>

                {!mesa &&
                <div className='articulo-modal__precio'>
                    Precio {parseFloat(precio) !== articulo.preciominimo ? 'base' : ''}
                    { articulo.moneda }
                    {precio.toLocaleString('en', {minimumFractionDigits: 2})}
                </div>
                }

                {!articulo.desactivado &&
                <form className='articulo-modal__form'>
                    {modificadores}
                    {mesa &&
                    <Form.Group className='articulo-modal__comentarios'>
                        <Form.Label className='articulo-modal__comentarios__title'>Comentarios</Form.Label>
                        <Form.Control
                            placeholder="(ej. sin sal)"
                            className='articulo-modal__comentarios__textarea'
                            as="textarea"
                            rows="3"
                            value={comentarios}
                            onChange={e => dispatch(setComentarios(e.target.value))}/>
                    </Form.Group>
                    }
                </form>
                }
            </ScrollableModalBody>

            {mesa && !articulo.desactivado &&
            <Modal.Footer>
                <Container>
                    <Row className='articulo-modal__footer'>
                        <Col sm='6' className='articulo-modal__footer-left'>
                            <div className='articulo-modal__cantidad-line'>
                                <span>Cantidad:&nbsp;</span>
                                <FaMinusCircle className={menosClasses.join(' ')}
                                               onClick={() => updateCantidad(cantidad - 1)}/>
                                &nbsp;
                                <input className={cantidadClasses.join(' ')} type="text" maxLength="2"
                                       size="2" value={cantidad}
                                       onChange={e => updateCantidad(e.target.value, true)}
                                       onBlur={e => updateCantidad(e.target.value, true)}
                                />
                                &nbsp;
                                <FaPlusCircle className={masClasses.join(' ')}
                                              onClick={() => updateCantidad(cantidad + 1)}/>
                            </div>
                        </Col>
                        <Col sm='6' className='articulo-modal__footer-right'>
                            <div className='articulo-modal__total'>
                                {articulo.moneda}
                                {(precio * cantidad).toLocaleString('en', {minimumFractionDigits: 2})}
                            </div>
                            {canSubmit &&
                            <Button variant="primary" size="sm" className='articulo-modal__submit'
                                    onClick={e => {
                                        dispatch(addCarrito({articulo, articulomodificadores, editindex}));
                                        dispatch(resetArticulo());
                                    }}>
                                {editindex === -1 ? 'Agregar al carrito' : 'Guardar cambios'}
                            </Button>
                            }
                            {!canSubmit &&
                            <Button variant="danger" size="sm" className='articulo-modal__submit'
                                    disabled={true}>Revise opciones</Button>
                            }
                        </Col>
                    </Row>
                </Container>

            </Modal.Footer>
            }
        </Modal>
    );
}


export default ArticuloModal;