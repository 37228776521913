import axios from "axios";
import config from "../config";

function pideSucursalStatus() {
    return axios({
        method: 'POST',
        url: config.url,
        headers: {
            'Content-Type': 'text/xml; charset=utf-8'
        },
        data: `<?xml version="1.0" encoding="UTF-8"?>
                <request command="pideSucursalStatus">
                <auth url="${config.qrCode}" />
                <pideSucursalStatus></pideSucursalStatus>
                </request>`
    });
}

export default pideSucursalStatus;